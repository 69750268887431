import { UserCredential } from 'firebase/auth'
import { NewBackEndApiResponseData } from 'types/ApiResponseData'
import { UserProfile, UserRole } from 'types/V3/User'
import { apiClient } from 'utils/apiClient'

type SwapTokenOptions = {
  role?: Omit<UserRole, 'cohart-advisor'>
}

// this function is used for setting current user on the web
// this should be called after the user is authenticated (either by sign-up or sign-in)
const swapCohartToken = async (userCredential: UserCredential, options: SwapTokenOptions = {}) => {
  const idToken = await userCredential.user.getIdToken()
  const { role } = options // update role to 'creator' or 'independent-advisor' if requested by the caller
  const response = await apiClient.post('/v2/auth/oauth/firebase-new', { token: idToken, role })
  const { data, ok, message } = response.data as NewBackEndApiResponseData<{
    user: UserProfile
    isEmailVerified?: boolean
  }>

  // user already signed up but not verified email
  // if `isEmailVerified` is not present (undefined), it means the user is already verified or not signed up yet or username/password are wrong
  if (!ok && data.isEmailVerified === false) {
    return data // handle this case in the caller
  }

  // throw error for other cases
  if (!ok) {
    const errorMessage = typeof message === 'string' ? message : 'Cannot verify Firebase IdToken'
    throw errorMessage
  }

  return data
}

export default swapCohartToken
